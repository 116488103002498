import React, { useEffect, useRef } from "react";
import { cn } from "@siteimprove/fancylib";
import { Toast, ToastProps } from "./toast";
import * as scss from "./toast-container.scss";

type ToastContainerProps = {
	toast: ToastProps | null;
};

export function ToastContainer(props: ToastContainerProps): JSX.Element {
	const { toast } = props;
	const toastRef = useRef<HTMLDialogElement | null>(null);
	const toastContainerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (toast !== null && toastRef.current) {
			toastRef.current.show();
		}
	}, [toast, toastRef.current]);

	return (
		<section
			ref={toastContainerRef}
			data-component="toast-container"
			className={cn(scss.toastContainer)}
		>
			{toast && <Toast ref={toastRef} {...toast} />}
		</section>
	);
}
