import React from "react";
import ReactDOM from "react-dom";
import { Store, useGlobalHook } from "../../../utils/global-state-hook";

import { ToastProps } from "./toast";
import { ToastContainer } from "./toast-container";
import { findOrCreateToastDropzone } from "./toast-dropzone";

type GlobalToastState = {
	toast: ToastProps;
} | null;

export function useShowToast(): (props: ToastProps) => void {
	const [, { showToast }] = useGlobalToaster();
	return showToast;
}

export function useClearToast(): () => void {
	const [, { clearToast }] = useGlobalToaster();
	return clearToast;
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobalToaster = useGlobalHook(null as GlobalToastState, {
	showToast: (store: Store<GlobalToastState>, toast: ToastProps) => {
		// this setState call is necessary to show an animation when replacing a toast
		store.setState(null);
		store.setState({
			toast: {
				...toast,
				onClose: () => {
					store.setState(null);
					toast.onClose?.();
				},
			},
		});

		const dropzone = findOrCreateToastDropzone();
		ReactDOM.render(<ToastProvider />, dropzone);
	},
	clearToast: (store: Store<GlobalToastState>) => {
		store.setState(null);
	},
});

function ToastProvider(): JSX.Element {
	const [state] = useGlobalToaster();
	const toast = state?.toast || null;
	return <ToastContainer toast={toast} />;
}
