/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

/**
 * A function that updates the state for all listeners in the store's listener array to pass down the updated state to all components using global state
 * @param newState - Updated state that is supposed to get stored in the global state store
 * @param store - Global state store
 */
function setState<T>(newState: T, store: Store<T>): void {
	store.state = newState;
	store.listeners.forEach((listener) => {
		listener(store.state);
	});
}

/**
 * A custom hook that adds listeners to the global state store's listener array
 * @param store - Global state store
 * @returns The global state store's state
 */
function useCustom<T>(store: Store<T>) {
	const [, newListener] = useState<T>();
	useEffect(() => {
		store.listeners.push(newListener);
		return () => {
			store.listeners = store.listeners.filter((listener) => listener !== newListener);
		};
	}, []);
	return store.state;
}

type RemoveFirstArg<T> = T extends (a0: infer T0) => infer R
	? () => R
	: T extends (a0: infer T0, a1: infer T1) => infer R
	? (a1: T1) => R
	: T extends (a0: infer T0, a1: infer T1, a2: infer T2) => infer R
	? (a1: T1, a2: T2) => R
	: T extends (a0: infer T0, a1: infer T1, a2: infer T2, a3: infer T3) => infer R
	? (a1: T1, a2: T2, a3: T3) => R
	: never;

type FunctionFirstArg<TArg, TRet> =
	| ((a0: TArg, a1: any) => TRet)
	| ((a0: TArg, a1: any, a2: any) => TRet)
	| ((a0: TArg, a1: any, a2: any, a3: any) => TRet)
	| ((a0: TArg, a1: any, a2: any, a3: any, a4: any) => TRet);

function associateActions<T extends { [key: string]: FunctionFirstArg<TArg, void> }, TArg>(
	arg: TArg,
	obj: T
): { [P in keyof T]: RemoveFirstArg<T[P]> } {
	return Object.entries(obj).reduce((agg, [key, val]) => {
		return { ...agg, [key]: val.bind(null, arg) };
	}, {}) as { [P in keyof T]: RemoveFirstArg<T[P]> };
}

export interface Store<T> {
	state: T;
	listeners: ((newState: T) => void)[];
	setState: (newState: T) => void;
}
type Action<T> = FunctionFirstArg<Store<T>, void>;

/**
 * A hook that initiates a global state store
 * @param initialState - Initial state
 * @param actions - Functions to update global state
 * @returns A function which returns a global state store of the type it was invoked with and an object of functions to update the global state
 */
export function useGlobalHook<T, TActions extends { [key: string]: Action<T> }>(
	initialState: T,
	actions: TActions
): () => [T, { [P in keyof TActions]: RemoveFirstArg<TActions[P]> }] {
	const store = {
		state: initialState,
		listeners: [],
		setState: (newState: T) => {
			setState(newState, store);
		},
	};
	const aactions = associateActions(store, actions);
	/* eslint-disable react-hooks/rules-of-hooks */
	return () => [useCustom(store), aactions];
}
