import React, { forwardRef, ReactNode, useRef, useState } from "react";
import {
	Icon,
	IconSuccess,
	IconInfoOutline,
	IconWarning,
	IconWarningOutline,
	IconError,
	IconClose,
	Button,
	cn,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import { assignKnobName } from "../../../utils/documentation-utils";
import { useTheme } from "../../context/theme/theme";
import * as scss from "./toast.scss";

export type ToastType = "success" | "info" | "warning" | "error";

export type ToastProps = {
	message: ReactNode;
	type: ToastType;
	onClose?: () => void;
	"aria-label"?: string;
};

export const Toast = forwardRef<HTMLDialogElement, ToastProps>((props, ref): JSX.Element => {
	const { message, type, onClose, "aria-label": ariaLabel } = props;
	const [unmounting, setUnmounting] = useState(false);
	const innerRef = useRef<HTMLDialogElement | null>(null);
	const i18n = useLabTranslations();
	const hasWarning = type === "warning" || type === "error";
	const labelFromType = useTypeToAriaLabel(type);
	const label = ariaLabel ? ariaLabel : labelFromType;

	return (
		<div
			data-component="toast"
			className={cn(scss.toastOuter, unmounting && scss.fadeOut)}
			onAnimationEnd={() => {
				if (unmounting) {
					onClose?.();
					innerRef.current?.close();
				}
			}}
			role="region"
			aria-label={label}
		>
			<dialog
				ref={(node) => {
					innerRef.current = node;
					if (typeof ref === "function") {
						ref(node);
					} else if (ref) {
						ref.current = node;
					}
				}}
				className={scss.toastInner}
				role={hasWarning ? "alertdialog" : undefined}
				onKeyDown={(e) => {
					if (e.keyCode === KeyCode.Escape) {
						setUnmounting(true);
					}
				}}
				aria-label={label}
			>
				<div className={cn(scss.icon, scss[type])}>
					<Icon size="large">
						<ToastIcon type={type} />
					</Icon>
				</div>
				<div className={scss.content}>{message}</div>
				<Button
					aria-label={i18n.closeDialog}
					onClick={() => setUnmounting(true)}
					variant="borderless"
					className={scss.close}
				>
					<Icon>
						<IconClose />
					</Icon>
				</Button>
			</dialog>
		</div>
	);
});

function useTypeToAriaLabel(type: ToastType) {
	const i18n = useLabTranslations();

	switch (type) {
		case "success":
			return i18n.messagePositive;
		case "info":
			return i18n.messageNeutral;
		case "warning":
			return i18n.messageWarning;
		case "error":
			return i18n.messageNegative;
	}
}

type ToastIconProps = {
	type: ToastType;
};

function ToastIcon(props: ToastIconProps): JSX.Element {
	const activeTheme = useTheme();

	switch (props.type) {
		case "success":
			return <IconSuccess />;
		case "info":
			return <IconInfoOutline />;
		case "warning":
			return activeTheme === "legacy" ? <IconWarning /> : <IconWarningOutline />;
		case "error":
			return <IconError />;
	}
}

assignKnobName(Toast, "Toast");
Toast.displayName = "Toast";
